<template>
  <div class="wrapper">
    <div class="inner-wrapper">
      <div class="accounts-form">
        <h1
          class="accounts-form__small-bi-header cursor"
          @click="$router.push('/introduction/main')"
        >
          Qpick
        </h1>
        <h1 class="accounts-form__header">휴면해제 안내</h1>
        <div class="accounts-form__description">
          고객님 계정의 휴면상태가 해제되었습니다.
          <button type="button" @click="toggleTerms">
            휴면계정 정책 안내 보기
          </button>
        </div>
        <p class="accounts-infobox">
          <span>아이디</span>
          <span>{{ getLoggedData.privacy?.email }}</span>
          <span>휴면전환일</span>
          <span>
            {{ getLoginData.inactivatedAt | yyyymmdd }}
          </span>
        </p>
        <button class="accounts-form__submit" @click="submit">확인</button>
      </div>
    </div>
    <termsPopup
      v-show="displayedTerms"
      title="휴면계정 정책"
      :contents="contents"
      @termsClose="toggleTerms"
    />
  </div>
</template>

<script>
import "@/assets/scss/accounts/basis.scss";
import {createNamespacedHelpers} from "vuex";
import {verifyLastPasswordChanged} from "@/utils/user.js";

const ModuleAuth = createNamespacedHelpers("ModuleAuth");

export default {
  data() {
    return {
      displayedTerms: false
    };
  },
  computed: {
    contents() {
      return "정보통신망 이용촉진 및 정보보호 등에 관한 법률에 따라, 장기간 서비스를 이용하지 않는 회원의 개인정보를 보호하기 위하여 아래와 같이 휴면 정책을 시행합니다.<br><br>1차 휴면계정<br>서비스를 1년간 로그인하지 않은 계정을 휴면 상태로 전환하고, 계정 및 회원 정보를 분리 보관합니다.<br><br>2차 휴면계정<br>1차 휴면계정으로 전환된 후 1년 동안 로그인하지 않을 경우, 분리 보관하고 있던 계정 및 회원 정보를 파기하고 계정을 탈퇴 처리합니다.";
    },

    ...ModuleAuth.mapGetters(["getLoggedData", "getLoginData"])
  },
  methods: {
    // 휴면계정 정책 안내 보기
    toggleTerms() {
      this.displayedTerms = !this.displayedTerms;
    },
    // 휴면해제 확인
    submit() {
      verifyLastPasswordChanged(this.getLoggedData?.lastPasswordChangedAt)
        ? this.$router.push(`/admin/dashboard`)
        : this.$router.push(`/accounts/password-change?campaign=y`);
    }
  },
  components: {
    termsPopup: () => import("@/components/accounts/terms-popup.vue")
  }
};
</script>

<style lang="scss" scoped>
.inner-wrapper {
  z-index: 2;
}
.accounts-infobox {
  padding: 36px 22px;

  span {
    margin: 5px 0;
  }
  span:nth-child(odd) {
    width: 96px;
    color: #606060;
  }
  span:nth-child(even) {
    min-width: 145px;
  }
}
.accounts-form__submit {
  margin-top: 24px;
}
.accounts-form__description button {
  color: #309574;
  font-size: 14px;
  text-decoration: underline;
}
</style>
