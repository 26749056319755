/* eslint-disable */
"use strict";

// 비밀번호 검증
export function verifyLastPasswordChanged(lastChanged) {
  const changedDate = new Date(timestampFilter(lastChanged));
  const today = new Date();
  const gap = Math.floor(
    (today.getTime() - changedDate.getTime()) / (1000 * 3600 * 24)
  );

  // 6개월 이상 경과 시, 비밀번호 변경 캠페인 이동
  return gap < 180 ? true : false;
}

// 날짜형식 필터링
export function timestampFilter(value, type) {
  if (value === "") return "";

  let date = new Date(value);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;

  let result =
    type === "timestamp"
      ? year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      : year + "-" + month + "-" + day;

  return result;
}

export function chkValidationPassword(v, customMsg) {
  try {
    //비밀번호 필드 미입력
    if (!v) throw !customMsg ? "비밀번호를 입력해주세요." : customMsg + "비밀번호를 입력해주세요."

    //영문, 숫자, 특문, 길이 체크
    const pattern1 = /[0-9]/;
    const pattern2 = /[a-zA-Z]/;
    // const pattern3 = /[~!@#$%^&*()_+|<>?:{}]/;
    const pattern4 = /^[\x21-\x7e]{10,20}$/;

    if(!pattern1.test(v) || !pattern2.test(v) /*|| !pattern3.test(v)*/ || !pattern4.test(v)) throw "영문, 숫자, 특수문자 조합 10 ~ 20자로 입력하세요.";

    //연속된 문자, 숫자 체크(ex. abc, 321)
    const charStr = [...v].map(value => value.charCodeAt());
    let preStr = 0;
    let chr = 0;

    charStr.forEach(s => {
      if(Math.abs(preStr - s) === 1) {
        chr++;
      }
      preStr = s;
    });

    if(chr > 2) throw "동일하거나 연속된 3자리 이상의 영문 또는 숫자는 사용할 수 없습니다.";


    //동일한 문자, 숫자 체크(ex. aaa, 111)
    if(/(\w)\1\1/.test(v)) throw "동일하거나 연속된 3자리 이상의 영문 또는 숫자는 사용할 수 없습니다.";

  } catch (e) {
    return {result: false, msg: e};
  }

  return {result: true, msg: "성공"};
}
