var render = function () {
  var _vm$getLoggedData$pri;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "inner-wrapper"
  }, [_c('div', {
    staticClass: "accounts-form"
  }, [_c('h1', {
    staticClass: "accounts-form__small-bi-header cursor",
    on: {
      "click": function click($event) {
        return _vm.$router.push('/introduction/main');
      }
    }
  }, [_vm._v(" Qpick ")]), _c('h1', {
    staticClass: "accounts-form__header"
  }, [_vm._v("휴면해제 안내")]), _c('div', {
    staticClass: "accounts-form__description"
  }, [_vm._v(" 고객님 계정의 휴면상태가 해제되었습니다. "), _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.toggleTerms
    }
  }, [_vm._v(" 휴면계정 정책 안내 보기 ")])]), _c('p', {
    staticClass: "accounts-infobox"
  }, [_c('span', [_vm._v("아이디")]), _c('span', [_vm._v(_vm._s((_vm$getLoggedData$pri = _vm.getLoggedData.privacy) === null || _vm$getLoggedData$pri === void 0 ? void 0 : _vm$getLoggedData$pri.email))]), _c('span', [_vm._v("휴면전환일")]), _c('span', [_vm._v(" " + _vm._s(_vm._f("yyyymmdd")(_vm.getLoginData.inactivatedAt)) + " ")])]), _c('button', {
    staticClass: "accounts-form__submit",
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("확인")])])]), _c('termsPopup', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.displayedTerms,
      expression: "displayedTerms"
    }],
    attrs: {
      "title": "휴면계정 정책",
      "contents": _vm.contents
    },
    on: {
      "termsClose": _vm.toggleTerms
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }